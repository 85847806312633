import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/about.vue')
  },
  {
    path: '/insurance',
    name: 'insurance',
    component: () => import('../views/insurance.vue')
  },
  {
    path: '/technology',
    name: 'technology',
    component: () => import('../views/technology.vue')
  },
  {
    path: '/articleList',
    name: 'articleList',
    component: () => import('../views/articleList.vue')
  },
  {
    path: '/infos',
    name: 'infos',
    component: () => import('../views/infos.vue')
  },
  {
    path: '/baoxian',
    name: 'baoxian',
    component: () => import('../views/baoxian.vue')
  },
  {
    path: '/guize',
    name: 'guize',
    component: () => import('../views/guize.vue')
  },
  {
    path: '/onde',
    name: 'onde',
    component: () => import('../views/onde.vue')
  },
  {
    path: '/taipingyang',
    name: 'taipingyang',
    component: () => import('../views/taipingyang.vue')
  },
  {
    path: '/texe',
    name: 'texe',
    component: () => import('../views/texe.vue')
  },
  {
    path: '/text',
    name: 'text',
    component: () => import('../views/text.vue')
  },
  {
    path: '/weixin',
    name: 'weixin',
    component: () => import('../views/weixin.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
