<template>
  <div>
    <header class="header">
      <div class="opacityHd" :class="{on:scroll<250?'on':''}" v-if="scroll==0">
        <div class="w clearfix" style="position: relative">
          <h1 class="logo fl">
            <a style="margin:0;" href="/"></a>
          </h1>
          <!-- <div class="switchLang fr">
            <a class="en">EN</a>
            <a class="cn">中文</a>
          </div> -->
          <div class="nav fr">
            <ul class="clearfix">
              <li>
                <router-link to="/">首页 HOME</router-link>
                <!-- <a href="/">首页 HOME</a> -->
              </li>
              <li>
                <router-link to="/technology">数字产品</router-link>
                <!-- <a href="/technology">保险科技</a> -->
              </li>
              <li class="zq-drop-list">
                <a>信息资讯</a>
                <ul class="subMenu none">
                  <!-- <li>
                    <a
                      href="https://hc.chechegroup.com/#/active/MadeSpecial/e457ddef7f2c4f25"
                      target="_blank"
                    >家庭保障规划</a>
                  </li> -->
                  <li>
                    <router-link to="/articleList">行业资讯</router-link>
                  </li>
                </ul>
              </li>
              <li>
                <router-link to="/insurance">机构介绍</router-link>
              </li>
              <li>
                    <router-link to="/about"> 关于我们 </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="whiteHd" :class="{on:scroll>250?'on':''}" v-if="scroll>250">
        <div class="w clearfix" style="position: relative">
            <!-- loge -->
          <h1 class="logo fl">
            <a style="margin:0;" href="/"></a>
          </h1>
          <!-- <div class="switchLang fr">
            <a class="en">EN</a>
            <a class="cn">中文</a>
          </div> -->
          <div class="nav fr">
            <ul class="clearfix">
              <li>
                <router-link to="/">首页 HOME</router-link>
              </li>
              <li>
                <router-link to="/technology">数字产品</router-link>
              </li>
              <li class="zq-drop-list">
                <a>信息资讯</a>
                <ul class="subMenu none">
                  <li>
                    <router-link to="/articleList">行业资讯</router-link>
                  </li>
                </ul>
              </li>
              <li>
                <router-link to="/insurance">机构介绍</router-link>
              </li>
              <li>
                <router-link to="/about"> 关于我们</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="el-dialog__wrapper loginDialog" style="display: none">
        <div
          role="dialog"
          aria-modal="true"
          aria-label="dialog"
          class="el-dialog"
          style="margin-top: 15vh; width: 420px"
        >
          <div class="el-dialog__header">
            <span class="el-dialog__title"></span>
            <button type="button" aria-label="Close" class="el-dialog__headerbtn">
              <i class="el-dialog__close el-icon el-icon-close"></i>
            </button>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  data() {
    return {
      scroll: 0
    };
  },
  props:{
    routeUrl:{
        type :String ,
        default:""
    }
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  methods: {
    handleScroll(e) {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 250) {
        this.scroll = scrollTop;
      } else {
        this.scroll = 0;
      }
    },



  }
};
</script>

<style lang="scss" scoped>
.w {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.bgf9 {
  background: #f9f9f9 !important;
}

.clearfix {
  zoom: 1;
}

.clearfix:after {
  content: "";
  display: block;
  width: 100%;
  overflow: hidden;
  clear: both;
}

.header {
  height: 100px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.header .member {
  margin: 35px 0 0 50px;
  height: 30px;
  line-height: 30px;
  color: #fff;
  position: absolute;
  right: 0;
  cursor: pointer;
}

.headerEN .member {
  margin: 35px 0 0 10px;
  font-size: 14px;
}

.header .member .icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  background: url(../../assets/img/xiaoyu.jpg) no-repeat;
  background-size: contain;
  vertical-align: middle;
  margin-right: 8px;
}

.header .member .icon.on {
  background-image: url(../../assets/img/xiaoyu.jpg)
}

.header .opacityHd,
.header .whiteHd {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 0.6s cubic-bezier(0.51, 0.01, 0, 1);
  transform: translateY(-100%);
}

.header .opacityHd.on,
.header .whiteHd.on {
  transform: translateY(0);
}

.header .opacityHd {
  background: rgba(0, 0, 0, 0.4);
}

.header .whiteHd {
  background: #fff;
  box-shadow: 0 7px 22px 0 rgba(0, 0, 0, 0.08);
  z-index: 33;
  color: #353a3e;
}

.header .whiteHd .member,
.header .whiteHd .nav li a {
  color: #353a3e;
}

.header .whiteHd .switchLang a.en {
  background: rgba(53, 58, 62, 0.1);
  color: #8a8a8a;
}

.header .whiteHd .switchLang a.cn,
.header .whiteHd .switchLang a.enn {
  background: rgba(53, 58, 62, 0.9);
  color: #fff;
}

.header .whiteHd .switchLang a.cnn {
  background: rgba(53, 58, 62, 0.1);
  color: #8a8a8a;
}

.header .whiteHd .logo a{
    margin-top: 1px;
    width: 200px;
    height: 100px;
  background: url("../../assets/img/newloge.png") no-repeat;
  background-size: 100% 100%;
}

.header .logo a {
  display: inline-block;
  width: 200px;
  height:100px;
  background: url("@/assets/img/newloge.png") no-repeat;
  background-size: 100% 100%;
  margin-top: 1px;
  overflow: hidden;
  text-indent: -999px;
}

.header .nav {
  margin-right: 160px;
}

.headerEN .nav {
  margin-right: 10px;
}

.header .nav li {
  float: left;
  position: relative;
}

.header .nav li:hover {
  background: rgba(44, 44, 42, 0.6);
}

.header .whiteHd .nav li:hover a {
  color: #fff;
}

.header .nav li:hover a:after {
  content: "";
  position: absolute;
  left: 50%;
  margin-left: -10px;
  bottom: 30px;
  width: 20px;
  height: 3px;
  background: #0fc191;
  border-radius: 2px;
}

.zq-drop-list {
  text-align: center;
}

.subMenu {
  position: absolute;
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: none;
}

.zq-drop-list:hover > a {
  display: block;
  color: #fff !important;
}

.zq-drop-list:hover .subMenu {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: 100px;
  display: block;
}

.zq-drop-list .subMenu li {
  float: none !important;
  width: 100%;
  padding: 0;
  list-style: none;
}

.header .nav li:hover .subMenu li a:after {
  background: transparent !important;
}

.zq-drop-list .subMenu li a {
  display: block;
  padding: 25px 0 !important;
  line-height: 20px;
  font-size: 14px !important;
  background: rgba(44, 44, 42, 0.8);
  color: #eee !important;
}

.header .nav .zq-drop-list .subMenu li a:after {
  background: none !important;
}

.zq-drop-list .subMenu li:last-child a {
  border-radius: 0 0 6px 6px;
}

.zq-drop-list .subMenu li:hover a {
  color: #fff !important;
}

.header .nav li a {
  display: block;
  color: #fff;
  font-size: 16px;
  line-height: 20px;
  padding: 40px;
}

.headerEN .nav li a {
  padding: 40px 20px;
}

.header .nav li a span {
  display: none;
}

.header .nav li.on a {
  font-weight: 700;
}

.header .nav li.on a span {
  display: inline-block;
}

.header .nav li.on:before {
  content: "";
  position: absolute;
  left: 50%;
  margin-left: -10px;
  bottom: 30px;
  width: 20px;
  height: 3px;
  background: #0fc191;
  border-radius: 2px;
}

.header .nav li:last-child {
  margin-right: 0;
}

.header .subMenu li:last-child {
  border-radius: 0 0 6px 6px;
}

.header .switchLang {
  height: 30px;
  margin-top: 35px;
}

.header .switchLang a {
  display: inline-block;
  width: 50px;
  height: 30px;
  line-height: 30px;
//   float: left;
  text-align: center;
  cursor: pointer;
}

.header .switchLang a.en {
  background: hsla(0, 0%, 100%, 0.2);
  color: #e6e6e6;
}

.header .switchLang a.cn {
  background: hsla(0, 0%, 100%, 0.9);
  color: #353a3e;
}

.header .switchLang a.cnn {
  background: hsla(0, 0%, 100%, 0.2);
  color: #e6e6e6;
}

.header .switchLang a.enn {
  background: hsla(0, 0%, 100%, 0.9);
  color: #353a3e;
}
</style>