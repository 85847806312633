<template>
  <div>
    <header-top></header-top>
    <div class="main">
      <div class="page-container page-component">
        <div>
          <div>
            <div class="indexMain">
              <div class="banner" style="height: 1080px">
                <div class="w">
                  <div class="textBox">
                    <h3>
                   QUALITY DEDICATION      
                      <br /> INTEGRITY INNOVATION 
                    </h3>
                    <p>
                        诚信·创新·优质·敬业</p>
                  </div>
                </div>
              </div>
              <div class="section-mode bgf9">
                <div class="w">
                  <div class="hd">
                    <h3>领先的数字产品综合服务平台</h3>
                  </div>
                  <div class="bd">
                    <div class="content clearfix modeContent1">
                      <div class="tab fl">
                        <div
                          class="item"
                          :class="{on:platformTab=='scienceAndTechnology'}"
                          @click="handleClickActiveTab('scienceAndTechnology')"
                        >
                          <h3>技术服务</h3>
                          <p>TECHNiICAL SERVICE</p>
                        </div>
                        <div
                          class="item"
                          :class="{on:platformTab=='basis'}"
                          @click="handleClickActiveTab('basis')"
                        >
                          <h3>基础设施</h3>
                          <p>INFRASTRUCTURE</p>
                        </div>
                      </div>
                      <div class="tabContent fl">
                        <div
                          class="contentItem clearfix"
                          :class="{on:platformTab=='scienceAndTechnology'}"
                          v-if="platformTab == 'scienceAndTechnology'"
                        >
                          <div class="item fl">
                            <img src="../assets/img/icon-1.316ae41a.png" alt />
                            <p>线上提卡功能</p>
                          </div>
                          <div class="item fl">
                            <img src="../assets/img/icon-3.cbab9c4c.png" alt />
                            <p>日常技术支撑</p>
                          </div>
                          <div class="item fl">
                            <img src="../assets/img/icon-1.316ae41a.png" alt />
                            <p>数据统计分析</p>
                          </div>
                        </div>
                        <div
                          class="contentItem clearfix"
                          :class="{on:platformTab=='basis'}"
                          v-if="platformTab == 'basis'"
                        >
                          <div class="item fl">
                            <img src="../assets/img/icon-4.4ceddfad.png" alt />
                            <p>服务网络</p>
                          </div>
                          <div class="item fl">
                            <img src="../assets/img/icon-5.c463873f.png" alt />
                            <p>经营资质</p>
                          </div>
                          <div class="item fl">
                            <img src="../assets/img/icon-6.d289733a.png" alt />
                            <p>合约管理</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="section PARTNER">
                <div class="hd">
                  <h3>合作伙伴</h3>
                  <p>PARTNERS</p>
                </div>
                <div class="bd">
                  <div class="tab">
                    <div
                      class="item"
                      :class="{on:cooperationTab=='insure'}"
                      @click="handleClickTab('insure')"
                    >生活出行</div>
                    <div
                      class="item"
                      :class="{on:cooperationTab=='VideoAudio'}"
                      @click="handleClickTab('VideoAudio')"
                    >视频影音</div>
                    <div
                      class="item"
                      :class="{on:cooperationTab=='knowledge'}"
                      @click="handleClickTab('knowledge')"
                    >知识教育</div>
                    <div
                      class="item"
                      :class="{on:cooperationTab=='restaurant'}"
                      @click="handleClickTab('restaurant')"
                    >餐饮美食</div>
                    <div
                      class="item"
                      :class="{on:cooperationTab=='lifetravel'}"
                      @click="handleClickTab('lifetravel')"
                    >保险公司</div>
                  </div>
                  <div class="tabContent">
                    <div
                      class="tabContent-item"
                      :class="{on:cooperationTab=='insure'}"
                      v-if="cooperationTab == 'insure'"
                    >
                    <ul class="clearfix">
                        <li class="bgf9">
                          <img src="../assets/img/icon2 (4).png" />
                        </li>
                        <li>
                          <img src="../assets/img/icon4 (3).png" />
                        </li>
                        <li class="bgf9">
                          <img src="../assets/img/icon1 (3).png" />
                        </li>
                        <li>
                          <img src="../assets/img/icon1 (4).png" />
                        </li>
                        <li>
                          <img src="../assets/img/icon3 (3).png" />
                        </li>
                        <li>
                          <img src="../assets/img/icon4 (4).png" />
                        </li>
                        <li>
                          <img src="../assets/img/icon2 (5).png" />
                        </li>
                        <li>
                          <img src="../assets/img/icon3 (4).png" />
                        </li>
                      </ul>
                    </div>
                    <div
                      class="tabContent-item"
                      :class="{on:cooperationTab=='VideoAudio'}"
                      v-if="cooperationTab == 'VideoAudio'"
                    >
                      <ul class="clearfix">
                        <li class="bgf9">
                          <img src="../assets/img/icon1.png" />
                        </li>
                        <li>
                          <img src="../assets/img/icon2.png" />
                        </li>
                        <li class="bgf9">
                          <img src="../assets/img/icon3.png" />
                        </li>
                        <li>
                          <img src="../assets/img/icon4.png" />
                        </li>
                        <li>
                          <img src="../assets/img/icon5.png" />
                        </li>
                        <li>
                          <img src="../assets/img/icon6.png" />
                        </li>
                        <li>
                          <img src="../assets/img/icon7.png" />
                        </li>
                        <li>
                          <img src="../assets/img/icon8.png" />
                        </li>
                      </ul>
                    </div>
                    <div
                      class="tabContent-item"
                      :class="{on:cooperationTab=='knowledge'}"
                      v-if="cooperationTab == 'knowledge'"
                    >
                      <ul class="clearfix">
                        <li class="bgf9">
                          <img src="../assets/img/icon1 (1).png" />
                        </li>
                        <li>
                          <img src="../assets/img/icon2 (2).png" />
                        </li>
                        <li class="bgf9">
                          <img src="../assets/img/icon3 (1).png" />
                        </li>
                        <li class="bgf9">
                          <img src="../assets/img/icon4 (1).png" />
                        </li>
                        <li class="bgf9">
                          <img src="../assets/img/icon5 (1).png" />
                        </li>
                        <li class="bgf9">
                          <img src="../assets/img/icon6 (1).png" />
                        </li>
                        <li class="bgf9">
                          <img src="../assets/img/icon7 (1).png" />
                        </li>
                        <li class="bgf9">
                          <img src="../assets/img/icon8 (1).png" />
                        </li>
                      </ul>
                    </div>
                    <div
                      class="tabContent-item"
                      :class="{on:cooperationTab=='restaurant'}"
                      v-if="cooperationTab == 'restaurant'"
                    >
                      <ul class="clearfix">
                        <li class="bgf9">
                          <img src="../assets/img/icon1 (2).png" />
                        </li>
                        <li>
                          <img src="../assets/img/icon2 (3).png" />
                        </li>
                        <li class="bgf9">
                          <img src="../assets/img/icon3 (2).png" />
                        </li>
                        <li class="bgf9">
                          <img src="../assets/img/icon4 (2).png" />
                        </li>
                        <li class="bgf9">
                          <img src="../assets/img/icon5 (2).png" />
                        </li>
                        <li class="bgf9">
                          <img src="../assets/img/icon6 (2).png" />
                        </li>
                        <li class="bgf9">
                          <img src="../assets/img/icon7 (2).png" />
                        </li>
                        <li class="bgf9">
                          <img src="../assets/img/icon8 (2).png" />
                        </li>
                      </ul>
                    </div>
                    <div
                      class="tabContent-item"
                      :class="{on:cooperationTab=='lifetravel'}"
                      v-if="cooperationTab == 'lifetravel'"
                    >
                    <ul class="clearfix">
                        <li class="bgf9">
                          <img src="../assets/img/peopleinsureChina.png" />
                        </li>
                        <li>
                          <img src="../assets/img/chinalifeinsurance.png" />
                        </li>
                        <li class="bgf9">
                          <img src="../assets/img/pingAninChina.png" />
                        </li>
                        <li>
                          <img src="../assets/img/chinaPacificInsurance.png" />
                        </li>
                      </ul>
                     
                    </div>
                  </div>
                </div>
              </div>
              <!-- 新闻板块 -->
              <!-- <div class="section news bgf9">
                <div class="hd">
                  <h3>新闻</h3>
                  <p>NEWS</p>
                </div>
                <div class="bd">
                  <div class="w">
                    <div class="more">
                      <a href="/news">
                        新闻中心
                        <i class="icon"></i>
                      </a>
                    </div>
                    <div class="newsContent clearfix">
                      <div class="newsBig fl">
                        <a href="/news/64">
                          <div class="imgBox">
                            <img
                              src="https://manager.api.chetimes.com/resources/images/news/64/0.jpg"
                              alt
                            />
                          </div>
                          <div class="date">2022-11-16</div>
                          <h3>车车科技入选《亚洲保险评论》2022年度保险科技服务商前三甲</h3>
                          <div
                            class="desc"
                          >近日，亚洲保险行业专业杂志《亚洲保险评论》评选出2022亚洲保险行业年度大奖入围名单，车车科技入选2022年度保险科技服务商前三甲。亚洲保险行业年度大奖以其严格的标准和透明的选择过程而闻名，由来自保险行业的专家评审团监督，整个评审过程由毕马威审计。</div>
                          <div class="detail">
                            <span>
                              <i class="icon"></i> 进入详情
                            </span>
                          </div>
                        </a>
                      </div>
                      <div class="newsList fr">
                        <ul>
                          <li>
                            <a href="/news/63">
                              <div class="imgBox">
                                <img
                                  src="https://manager.api.chetimes.com/resources/images/news/63/0.jpg"
                                  alt
                                />
                              </div>
                              <h3>
                                车车科技入选2022红鲱鱼（Red
                                Herring）全球100强企业
                              </h3>
                              <div class="info clearfix">
                                <span class="fl">2022-11-01</span>
                                <span class="fr">进入详情</span>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="/news/62">
                              <div class="imgBox">
                                <img
                                  src="https://manager.api.chetimes.com/resources/images/news/62/0.jpg"
                                  alt
                                />
                              </div>
                              <h3>车车科技荣登“2022 IDC 中国 FinTech 50”榜单</h3>
                              <div class="info clearfix">
                                <span class="fl">2022-09-19</span>
                                <span class="fr">进入详情</span>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="/news/61">
                              <div class="imgBox">
                                <img
                                  src="https://manager.api.chetimes.com/resources/images/news/61/0.jpg"
                                  alt
                                />
                              </div>
                              <h3>车车科技集团入选“中关村金融科技30强”榜单</h3>
                              <div class="info clearfix">
                                <span class="fl">2022-07-29</span>
                                <span class="fr">进入详情</span>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="/news/60">
                              <div class="imgBox">
                                <img
                                  src="https://manager.api.chetimes.com/resources/images/news/60/0.jpg"
                                  alt
                                />
                              </div>
                              <h3>
                                助力“乡村振兴”
                                车车参与湖北省保险中介行业协会“防返贫帮扶”捐赠活动
                              </h3>
                              <div class="info clearfix">
                                <span class="fl">2022-07-22</span>
                                <span class="fr">进入详情</span>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer-bottom></footer-bottom>
  </div>
</template>

<script>
import headerTop from "@/components/header/index.vue";
import footerBottom from "@/components/footer/index.vue";
export default {
  components: {
    headerTop,
    footerBottom
  },
  data() {
    return {
     
      platformTab: "scienceAndTechnology",
      cooperationTab: "insure"
    };
  },

  mounted() {
    
  },

  methods: {
    //科技切换
    handleClickActiveTab(item) {
      this.platformTab = item;
    },

    // 合作伙伴切换
    handleClickTab(item) {
      console.log(item);
      this.cooperationTab = item;
    }
  }
};
</script>


<style lang="scss" scoped>
@import "../assets/css/element.css";
.banner {
  height: 1080px;
  width: 100%;
  overflow: hidden;
  background-size: cover;
}

.indexMainEn .section-mode .hd h3 {
  font-family: SourceSansPro-Semibold;
  font-size: 36px;
}

.indexMainEn .section-mode .hd p {
  font-size: 18px;
  line-height: 25px;
}

.indexMainEn .news .bd .newsList li h3 {
  font-size: 14px;
}

.indexMainEn .news .bd .newsBig h3 {
  font-size: 30px;
}

.indexMainEn .section-mode .bd .tab .item {
  padding-top: 64px !important;
}

.indexMainEn .section-mode .bd .tab .item h3 {
  font-size: 16px !important;
}

.section-mode .tabContent .item p {
  font-size: 18px !important;
  font-family: SourceSansPro-Light;
  line-height: 24px;
  text-align: center;
}

.indexMainEn .section-mode .tabContent .item {
  margin-right: 30px !important;
  text-align: center !important;
  width: 188px;
}

.indexMain .banner {
  height: 1080px;
  width: 100%;
  overflow: hidden;
  background: url(../assets/img/shouye.png) top no-repeat;
  background-size: cover;
}

.banner img {
  margin-left: -360px;
  max-width: none;
}

.banner .w {
  position: relative;
  height: 100%;
}

.banner .textBox {
  position: absolute;
  top: 50%;
  left: 50px;
  transform: translateY(-30%);
}

.banner .textBox h3 {
  font-size: 60px;
  color: #a1e1e4;
  font-weight: 400;
  line-height: 84px;
  margin-bottom: 0;
  font-family: SourceSansPro-Semibold;
}

.banner .textBox h4 {
  font-size: 46px;
  color: #a1e1e4;
  line-height: 65px;
}

.banner .textBox p {
  height: 88px;
  width: 329px;
  border: 1px solid #a1e1e4;
  line-height: 88px;
  font-size: 34px;
}

.banner .textBox .btn,
.banner .textBox p {
  text-align: center;
  color: #a1e1e4;
  margin-bottom: 20px;
}

.banner .textBox .btn {
  height: 42px;
  width: 190px;
  line-height: 42px;
  background: #0fc191;
  font-size: 26px;
}

.banner .textBox .line {
  height: 2px;
  width: 80px;
  background: #fff;
}

.section-mode {
  padding: 80px 0;
}

.section-mode .hd {
  text-align: center;
  margin-bottom: 50px;
}

.section-mode .hd h3 {
  font-size: 40px;
  color: #1c1f21;
}

.section-mode .hd p {
  color: #63686c;
  font-size: 22px;
  line-height: 1.5;
}

.section-mode .bd .content {
  height: 467px;
}

.section-mode .bd .modeContent1 {
  background: url(../assets/img/modeBg-1.585efe12.png) no-repeat;
}

.section-mode .bd .modeContent2 {
  background: url(../assets/img/modeBg-2.26128288.png) no-repeat;
}

.section-mode .bd .tab {
  margin-top: 87px;
  padding-left: 50px;
}

.section-mode .bd .tab .item {
  height: 165px;
  padding-top: 45px;
  box-sizing: border-box;
  padding-right: 37px;
  cursor: pointer;
}

.section-mode .tab .item h3 {
  font-size: 30px;
  line-height: 1.5;
}

.section-mode .tab .item p {
  font-size: 14px;
}

.section-mode .bd .tab .item.on {
  color: #0fc191;
  border-right: 2px solid #10c191;
}

.section-mode .tabContent {
  padding-left: 250px;
  padding-top: 147px;
}

.section-mode .contentItem {
  display: none;
}

.section-mode .contentItem.on {
  display: block;
}

.section-mode .tabContent .item {
  margin-right: 90px;
}

.section-mode .tabContent .item p {
  margin-top: 30px;
  color: #fff;
  font-size: 20px;
}

.section .hd {
  text-align: center;
  margin-bottom: 30px;
}

.section .hd h3 {
  font-size: 50px;
  line-height: 70px;
}

.section .hd p {
  color: #b0b2b4;
  font-size: 22px;
}

.PARTNER {
  padding-top: 80px;
  padding-bottom: 127px;
}

.PARTNER .tab {
  text-align: center;
  font-size: 0;
}

.PARTNER .tab .item {
  display: inline-block;
  width: auto !important;
  font-size: 30px;
  color: #63686c;
  padding-bottom: 17px;
  cursor: pointer;
  margin-right: 100px;
}

.PARTNER .tab .item:last-child {
  margin-right: 0;
}

.PARTNER .tab .item.on {
  color: #0fc191;
  border-bottom: 2px solid #0fc191;
}

.PARTNER .tabContent {
  max-width: 1200px;
  margin: 60px auto 0;
}

.PARTNER .tabContent-item {
  display: none;
}

.PARTNER .tabContent-item.on {
  display: block;
}

.PARTNER .tabContent-item li {
  float: left;
  width: 25%;
  height: 124px;
  text-align: center;
  line-height: 124px;
  background: hsla(0, 0%, 97.6%, 0.5);
}

.PARTNER .tabContent-item li img {
  max-width: 200px;
  max-height: 48px;
}

.news {
  padding: 80px 0;
}

.news .hd {
  margin-bottom: 0;
}

.news .bd .more {
  text-align: right;
  margin-bottom: 20px;
}

.news .bd .more a:hover {
  color: #0fc191;
}

.news .bd .more .icon {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAATBAMAAABW2/GaAAAAIVBMVEVHcEw0Oz41Oj41Oj41Oj4zOT8zM0Q0Oj42OT81Oz41Oj6LcnUCAAAACnRSTlMAnTXv6isPwVWPA7TLlgAAADJJREFUCNdjYCtiAIH0xQogKmSVEYhi9SKBy7hqJYiSWiUA5izEz9ECcxg4wBwGBrBBADPBEvpByH1HAAAAAElFTkSuQmCC)
    no-repeat;
}

.news .bd .more .icon,
.news .bd .more a:hover .icon {
  display: inline-block;
  width: 12px;
  height: 19px;
  margin-left: 22px;
  vertical-align: middle;
}

.news .bd .more a:hover .icon {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAATBAMAAABW2/GaAAAAG1BMVEX///8PwZEfxphs2bvM8ujW9e1Q0q+v6tp43MHUMUyaAAAAM0lEQVQI12NgSGEAATYhBxAVJKgColgVSeCyCUqAKENBAyDJLCiMn+MI5jCwgzkMDGCDABALBxd57DzkAAAAAElFTkSuQmCC)
    no-repeat;
}

.news .bd .more a {
  font-size: 26px;
  color: #353a3e;
}

.news .bd .newsBig {
  width: 600px;
  margin-right: 50px;
}

.news .bd .newsBig a {
  display: block;
}

.news .bd .newsBig a:hover h3 {
  color: #0fc191;
}

.news .bd .newsBig .imgBox {
  width: 600px;
  height: 300px;
  overflow: hidden;
}

.news .bd .newsBig .date {
  margin-top: 30px;
  color: #b0b2b4;
  line-height: 33px;
  font-size: 24px;
  margin-bottom: 11px;
}

.news .bd .newsBig h3 {
  font-size: 35px;
  line-height: 49px;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.news .bd .newsBig .detail span {
  margin-top: 49px;
  color: #0fc191;
  font-size: 20px;
}

.news .bd .newsBig .detail i {
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
  width: 70px;
  height: 70px;
  background: url(../assets/img/detailIcon.aad7a2c9.png) no-repeat;
}

.news .bd .newsBig .desc {
  line-height: 29px;
  font-size: 16px;
  color: #b0b2b4;
  margin-top: 11px;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.news .bd .newsBig .detail {
  margin-top: 49px;
}

.news .bd .newsList {
  width: 550px;
}

.news .bd .newsList li {
  float: left;
  width: 260px;
  margin-right: 30px;
}

.news .bd .newsList li a {
  float: left;
  display: block;
}

.news .bd .newsList li a:hover h3 {
  color: #0fc191;
}

.news .bd .newsList .imgBox {
  width: 260px;
  height: 130px;
  margin-bottom: 10px;
  overflow: hidden;
}

.news .bd .newsList li:nth-child(2n) {
  margin-right: 0;
}

.news .bd .newsList li:first-child,
.news .bd .newsList li:nth-child(2) {
  padding-bottom: 40px;
  border-bottom: 1px solid #eee;
  margin-bottom: 39px;
}

.news .bd .newsList li h3 {
  font-size: 16px;
  margin-bottom: 20px;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.news .bd .newsList li .info {
  font-size: 14px;
}

.news .bd .newsList li .info .fl {
  color: #b0b2b4;
}

.news .bd .newsList li .info .fr {
  color: #30b18e;
}

.loginDialog .el-dialog__body {
  padding-top: 7px;
}

.loginContainer h3 {
  text-align: center;
  font-size: 20px;
  margin-bottom: 30px;
}

.loginForm {
  padding: 0 10px;
}

.loginForm .el-input--prefix .el-input__inner {
  height: 50px;
  padding-left: 44px;
}

.loginForm .el-input__prefix {
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.loginForm .input-icon {
  display: inline-block;
  width: 16px;
  height: 20px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAiCAYAAABBY8kOAAAC80lEQVRIS+2Xz0sUYRjHv8+7u26rZkIdUrpkUScrxAyEQC/pzs7O7JJ18OCpS4egOmRQxBIdsi7+AxHYoRgtd5xZKQg2qEsHsUNQ9oOCflgIwVoeLGeemNlZW1dd3TWiw85t5n2e5zPP933mfZ4h/KOLCjnMTLpphglCAqOJiWvXfBcbIOIMiKbYgh6LyY8LfZaAkslUMwu+RcD+NYMXMyCkq3yiT5KkjzmzRVAymToEwQ8BFGbwA4BdNC7Bz4zqApsvAna7oijvnOcuSNO02qpN1VMAGp17BmYE8aX5QEA73t39bT3Z6breyOTvI/BFBmpcH8KEKkcOEhG7IN0YP8dsD7gQxjRsX3s8Hn6/HkChjZ5KtbLFj+DBBKhHUSJ3XVByzHwKoC37FuJYLCqNlAPJ+STHzH4AVz3JbquK3JsDZQDUAZjdsrlma2dn58JGQKlUavsvi6ezMXgypkRbciD29uZ5XJGbNwLJy2oOcAvkbUyRdy8BEWhSVSItfwOkG2aG2VWpAipN0Ip0pemVZ12RriLdogL/fzFomlYVDNZvE2LePYiFEJYkSV+d7rnSRpaV0eio0SH8pHun8WJcAj6BrQ5VVd8s67TlnN7JMfMCgCsrluAqHXm1jJyO6mPgdVyR9xQG1DQtFAiGTgkhdjpzX3adLACTalS6UShfIpEQB1pa5wH4QXgVi8p7c43vM4AGAFaVXzRIkjRT9gcEYNQw2ojJmUOcMeuJqsiHPZAxAtBRb2FQVeQzGwHpxrjObCtuPKJrajTSnwWZZhds3PeCsxA4q8jyYKmwdDrtn/0+d52B057vgo8CzdFo18s/k6phDoPRk1dSE4IwbDN/cCQtBmWbAkLQLgb3gpG/xwMxRT7vKZUNMTT0oKau/uc9gI6UmsnK1Ug3n01IJxIJcsfpJUO+pmm+YCh0km3qB2FHWUCiF0TisiqH7+T7L/tt8RbJMIx9FtAkWKz52+LoKpgzgG9KVcPODL/sWg1UVjLFnH4DagSzMvIcGpQAAAAASUVORK5CYII=)
    no-repeat;
  background-size: contain;
}

.loginForm .input-code {
  background-image: url("../assets/img/login_icon_2.938c110b.png");
}

.codeInput {
  display: flex;
  align-items: center;
}

.codeInput .el-input {
  flex: 1;
}

.codeInput {
  border: 1px solid #dcdfe6;
}

.loginContainer .sendCode {
  width: 115px;
  text-align: center;
  cursor: pointer;
  line-height: 1.5;
  border-left: 1px solid #b0b2b4;
  color: #b0b2b4;
  font-size: 16px;
}

.loginContainer .sendCode.enable {
  color: #0fc191;
}

.codeInput .el-input__inner {
  border: none;
}

.el-form-item.is-error .codeInput {
  border-color: #f56c6c;
}

.loginContainer .submit {
  height: 50px;
  border: none;
  background: #0fc191;
  color: #fff;
  width: 360px;
  margin: 0 10px;
  outline: none;
  font-size: 16px;
}

.loginContainer .submit:active {
  background: #05a677;
}

.page {
  text-align: center;
  margin-bottom: 15px;
}

.el-pagination.page button,
.page .el-pager li {
  font-size: 18px;
  width: 33px;
  height: 33px;
  line-height: 33px;
  border-radius: 50%;
  box-sizing: border-box;
  min-width: 33px;
  margin: 0 5px;
}

.page .el-pager li:hover {
  background: rgba(15, 193, 145, 0.15);
  color: #353a3e;
}

.page .el-pager li.active {
  color: #fff;
  background: #0fc191;
}

.el-message-box__btns .el-button--primary {
  background-color: #0fc191 !important;
  border-color: #0fc191 !important;
  color: #fff !important;
}

.el-message-box__btns .el-button--default:hover,
.el-message-box__btns .el-button:active {
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
}

.el-input.is-active .el-input__inner,
.el-input__inner:focus {
  border-color: #0fc191 !important;
}

.el-message {
  min-width: 280px !important;
  border-width: 1px;
  border-style: solid;
  border-color: #000 !important;
  background-color: rgba(0, 0, 0, 0.8) !important;
  border-radius: 5px;
}

.el-message,
.el-message--info .el-message__content {
  color: #fff !important;
}

.el-dialog__headerbtn .el-dialog__close {
  font-size: 20px !important;
}

.el-input__inner {
  border-radius: 0 !important;
}

.captcha-dialog {
  position: fixed;
  z-index: 9999;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.captcha-dialog .mask {
  background: #000;
  opacity: 0.3;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: block;
}

.captcha-dialog .wrapper {
  height: 370px;
  width: 414px;
  position: fixed;
  text-align: center;
  background: #fff;
  z-index: 3;
  left: 50%;
  top: 50%;
  border-radius: 20px;
  transform: translate3d(-50%, -50%, 0);
  padding: 0 20px;
}

.captcha-dialog .wrapper .captcha {
  width: 430px;
  height: 140px;
  margin: 10px auto;
}

.captcha-dialog .wrapper p {
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.captcha-dialog .wrapper input {
  width: 300px;
  height: 80px;
  line-height: 80px;
  box-sizing: border-box;
  border: 0;
  color: #99969f;
  font-size: 16px;
}

.captcha-dialog .wrapper a {
  display: inline-block;
}

.captcha-dialog .wrapper a.again-btn {
  margin-left: 20px;
  color: #0cd792;
  font-size: 14px;
  display: inline-block;
  height: 50px;
  padding: 10px 0.2px;
  box-sizing: border-box;
  border-radius: 20px;
}

.captcha-dialog .wrapper a.ok-btn {
  color: #fff;
  background-color: #0cd792;
  margin-top: 20px;
  font-size: 16px;
  width: 200px;
  height: 68px;
  line-height: 68px;
  border-radius: 20px;
}
</style>